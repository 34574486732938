// Override default variables before the import
$body-bg: #fff;

.form-header {
	color:#666;
	border-bottom: 1px solid #ddd;
	margin-left:-10px;
	margin-bottom:20px;
}
.qr-code {
	margin:0 auto;
	padding:3px;
	//round border 3px black
	border:3px solid #000;
	border-radius:30px;
	//shadow
	box-shadow: 0 0 5px rgba(0,0,0,0.2);
	//background
	background: #fff;
	width:70vw;
	max-width: 600px;
}

// Import Bootstrap and its default variables
@import '~bootstrap/scss/bootstrap.scss';